import React from "react"
import { Link } from "gatsby"
import { FaInstagram, FaEnvelope } from "react-icons/fa"
import { motion } from "framer-motion"
import logo from "../../assets/cropped_animated_logo.gif"

const LandingPage = ({ module }) => {
  const { customFields } = module

  const easing = [0.6, -0.5, 0.01, 0.99]

  const fadeInUp = {
    initial: {
      // y: 60,
      opacity: 0,
    },
    animate: {
      // y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        ease: easing,
      },
    },
  }

  const stagger = {
    animate: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  return (
    <div className="w-full h-screen flex flex-cols items-center justify- px-8">
      <div className="mx-auto">
        <img src={logo} alt="Bamo Records" className="w-80" />
        <motion.ul
          className="flex mt-8 justify-center"
          initial="initial"
          animate="animate"
          stagger={stagger}
        >
          <motion.li className="mr-8" variants={fadeInUp}>
            <a
              href={customFields.instagram.href}
              title={customFields.instagram.text}
              target={customFields.instagram.target}
            >
              <FaInstagram className="text-secondary text-3xl" />
            </a>
          </motion.li>
          <motion.li variants={fadeInUp}>
            <a
              href={customFields.email.href}
              title={customFields.email.text}
              target={customFields.email.target}
            >
              <FaEnvelope className="text-secondary text-3xl" />
            </a>
          </motion.li>
        </motion.ul>
      </div>
    </div>
  )
}

export default LandingPage
